<template>
  <v-dialog v-if="dialog" v-model="dialog" max-width="800">
    <v-card>
      <v-card-title>
        <back-button color="grey" @click="dialog = false" />
        <h1 class="title">Detalhes do agendamento</h1>
      </v-card-title>
      <v-card-text>
        <div>
          <section-title
            text="Dados da terapia (horário local em formato 24h)"
          />
          <property-value
            property="Data"
            :value="detailedAppointment.startAt | formatDate"
          />
          <property-value
            property="Horário início"
            :value="detailedAppointment.startAt | formatTime"
          />
          <property-value
            property="Horário fim"
            :value="detailedAppointment.endAt | formatTime"
          />
          <property-value
            property="Status"
            :value="detailedAppointment.statusString"
          />
          <template v-if="detailedAppointment.cancellationSource">
            <property-value
              property="Responsável por cancelamento"
              :value="detailedAppointment.cancellationSource | roleToString"
            />
            <property-value
              property="Motivo do cancelamento"
              :value="detailedAppointment.cancellationReason"
            />
          </template>
        </div>
        <div class="mt-5">
          <section-title text="Dados da(o) terapeuta" />
          <property-value
            property="Nome do terapeuta"
            :value="detailedAppointment.therapist.name"
          />
          <property-value
            property="Telefone do terapeuta"
            :value="detailedAppointment.therapist.phone"
          />
          <property-value
            property="E-mail do terapeuta"
            :value="detailedAppointment.therapist.mail"
          />
          <property-value
            property="Endereço do terapeuta"
            :value="detailedAppointment.therapist.address"
          />
          <property-value property="Ver endereço no mapa">
            <template #value>
              <a :href="linkMapAddress" target="_blank">Clique aqui</a>
            </template>
          </property-value>
        </div>
        <div class="mt-5">
          <section-title text="Dados do paciente" />
          <property-value
            property="Nome do paciente"
            :value="detailedAppointment.patient.name"
          />
          <property-value
            property="Telefone do paciente"
            :value="detailedAppointment.patient.phone"
          />
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { getAppointmentDetailsById } from "@/services/external/appointments-service";

export default {
  name: "DetailDialog",
  data: () => ({
    dialog: false,
    detailedAppointment: {
      patient: {},
      therapist: {},
    },
  }),
  computed: {
    linkMapAddress() {
      return `https://maps.google.com/?q=${this.detailedAppointment.therapist.address}`;
    },
  },
  methods: {
    async open(id) {
      const { data } = await getAppointmentDetailsById(id);
      this.detailedAppointment = data;
      this.dialog = true;
    },
  },
};
</script>

<style scoped></style>
